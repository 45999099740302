import { createSlice } from '@reduxjs/toolkit';

const groupSlice = createSlice({
    name: 'group',
    initialState: {
        groupId: null,
    },
    reducers: {
        setGroupId: (state, action) => {
            state.groupId = action.payload;
        },
        clearGroupId: (state) => {
            state.groupId = null;
        },
    },
});

export const { setGroupId, clearGroupId } = groupSlice.actions;
export default groupSlice.reducer;
