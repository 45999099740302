import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

function getInitialColorMode() {
    //추가된 부분
    const persistedColorPreference = window.localStorage.getItem('theme');

    if (persistedColorPreference) {
        return persistedColorPreference;
    }

    const systemPreference = window.matchMedia('(prefers-color-scheme: dark)');
    if (!systemPreference.matches) {
        return 'light';
    }
    return 'dark';
}

export const themeSlice = createSlice({
    name: 'theme',
    initialState: getInitialColorMode(),
    reducers: {
        update: (state, action) => {
            const { payload } = action;
            return payload;
        },
    },
});

export const { update, reset } = themeSlice.actions;

export default themeSlice.reducer;
