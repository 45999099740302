import { configureStore } from '@reduxjs/toolkit';
import themeReducer from './theme';
import companyReducer from './companySlice';
import groupReducer from './groupSlice';

export const store = configureStore({
    reducer: {
        theme: themeReducer,
        company: companyReducer,
        group: groupReducer,
    },
});
