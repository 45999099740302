import React, { Suspense, lazy, useEffect } from 'react';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { Route, Routes } from 'react-router-dom';
import 'styles/_variables.scss';
import 'styles/_mixin.scss';
import 'styles/reset.scss';
import 'styles/tailwind.css';
import 'styles/font.css';
import 'styles/global.scss';
import 'pages/company-view/company-view.scss';
import 'components/common/common.scss';
import MainLayout from './components/layout/MainLayout';
import { ThemeProvider } from './context/ThemeContext';
import Auth from './utils/auth';
const CompanyView = lazy(() => import('pages/company-view/CompanyView'));
const Conversation = lazy(() => import('pages/conversation/Conversation'));
const Login = lazy(() => import('pages/login/Login'));

function App() {
    function setScreenSize() {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    useEffect(() => {
        setScreenSize();

        // window.addEventListener('resize', setScreenSize); // resize 이벤트 리스너 추가

        // return () => {
        //     window.removeEventListener('resize', setScreenSize); // cleanup
        // };
    }, []); // 빈 의존성 배열로 변경

    useEffect(() => {
        // window.addEventListener('authTokenSet', () => {
        //     const token = localStorage.getItem('token');
        //     Auth.login(token);
        // });
        if (window.location.pathname === '/login') {
            return;
        }
        const isLoggedIn = Auth.isLoggedIn();
        if (!isLoggedIn) {
            window.location.href = '/login';
        }
    }, []);

    // useEffect(() => {
    //     const token = window.tokenFromRN || localStorage.getItem("authToken");
    //     if (token) {
    //       console.log("받은 토큰:", token);
    //       Auth.login(token);
    //       window.location.href = '/';
    //     }
    // }, []);

    return (
        <Provider store={store}>
            <ThemeProvider>
                <MainLayout>
                    <Suspense>
                        <Routes>
                            <Route path="/login" element={<Login />} />
                            <Route path="/company-view/:companyId" element={<CompanyView />} />
                            <Route path="/" element={<Conversation />} />
                        </Routes>
                    </Suspense>
                </MainLayout>
            </ThemeProvider>
        </Provider>
    );
}

export default App;
