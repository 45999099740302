import axios from 'axios';
import { queryClient } from 'index';

class Auth {
    static login(token) {
        if (token) {
            window.localStorage.setItem('token', token);
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }
    }
    static logout() {
        window.localStorage.removeItem('token');
        queryClient.clear();
        axios.defaults.headers.common['Authorization'] = '';
    }
    static isLoggedIn() {
        if (window.localStorage.getItem('token')) {
            return true;
        }
        return false;
    }
    static getToken() {
        return window.localStorage.getItem('token');
    }
}
export default Auth;
