import React from 'react';
import { createRoot } from 'react-dom/client'; // 변경된 부분
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from 'store/store';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import 'styles/tailwind.css';

export const queryClient = new QueryClient();

const container = document.getElementById('root');
const root = createRoot(container); // createRoot로 루트 생성

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <Router>
                <QueryClientProvider client={queryClient}>
                    {/* <ReactQueryDevtools initialIsOpen={false} /> */}
                    <App />
                </QueryClientProvider>
            </Router>
        </Provider>
    </React.StrictMode>
);

reportWebVitals();
