import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    companyInfo: { name: '' },
};

const companySlice = createSlice({
    name: 'companyInfo',
    initialState,
    reducers: {
        setCompanyInfo: (state, action) => {
            state.companyInfo = action.payload;
        },
    },
});

export const { setCompanyInfo } = companySlice.actions;
export default companySlice.reducer;
